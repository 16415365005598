@import '../constants.scss';

header {
  top: 0;
  width: stretch;
  color: $main-color-text;
  background: $main-color;
  position: sticky;
  z-index: 2;
  height: 50px;
  user-select: none;

  .hamburger {
    position: absolute;
    display: block;
    left: 0;
    font-size: 14pt;
    padding: 14px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 3;

    &:hover {
      background-color: #1b1b1b;
      cursor: pointer;
    }

    svg {
      transition: 200ms all;
    }

    &.rotated {
      color: black;
      svg {
        transform: rotate(90deg);
      }

      &:hover {
        background: whitesmoke;
      }
    }
  }

  .auth-row {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .title {
    display: block;
    text-align: center;
    font-size: 18pt;
    text-align: bottom;
    z-index: 0;
    padding-top: 12px;
  }

  .descriptor {
    display: none;
  }
}
