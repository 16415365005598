.landing-page {
  color: black;
  width: 100%;

  .artwork {
    object-fit: cover;
    width: stretch;
    background-repeat: no-repeat;
    align-items: center;
    display: grid;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    height: calc(100vh - 50px);
    background-position: center;

    @media (max-width: 1000px) {
      height: 60vh;
      margin-bottom: 1em;
    }
  }
}
