@import './campaign-overview.scss';
@import './campaign-art-and-episode.scss';

.campaign-explorer {
  color: black;
  width: 100%;

  .page-title {
    margin-top: 1em;
    margin-bottom: 1.5em;
    font-size: 48pt;
    font-family: 'Montserrat', sans-serif;
    display: none;
  }

  .content-area {
    margin-bottom: 0;
    .content-row {
      @media (min-width: 1001px) {
        margin-left: 15vw;
        margin-right: 15vw;
      }

      .content-item {
        min-height: 6em;
        height: 6em;
        width: stretch;
        max-width: initial;

        .description {
          display: none;
        }

        .title {
          margin-top: 1.2em;
        }

        @media (min-width: 1001px) {
          &:first-of-type {
            margin-left: 0;
          }

          &:nth-of-type(2) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .campaigns {
    .campaign-preview {
      width: stretch;
      font-size: 14pt;
      background: #1b1b1b;
      color: white;

      @media (min-width: 1001px) {
        margin-left: 15vw;
        margin-right: 15vw;
      }

      .top-panel {
        display: flex;
        margin-top: 2em;

        @media (max-width: 1000px) {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          margin-bottom: 1em;
        }
      }
    }
  }
}
