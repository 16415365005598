.auth-row {
  button {
    border: none;
    width: 6em;
    height: 50px;
    font-size: 12pt;
    color: white;
    background: black;
    transition: 100ms all;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
