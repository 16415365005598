.campaign-explorer {
  .campaigns {
    .campaign-preview {
      .top-panel {
        .overview {
          position: relative;
          display: block;

          @media (min-width: 1001px) {
            flex: 2;
            border-right: 1px solid white;
            border-left: 1px solid white;
            padding: 1em;
          }

          @media (max-width: 1000px) {
            min-height: 8em;
            border-bottom: 1px solid white;
            order: 2;
          }

          .title {
            margin-top: 2em;
            font-weight: bold;
            font-size: 18pt;

            @media (max-width: 1000px) {
              margin-top: 1.5em;
              font-size: 16pt;
              margin-bottom: 1em;
            }
          }
          
          .description {
            line-height: 1.5em;
            font-size: 11pt;
            font-style: italic;

            @media (min-width: 1001px) {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              height: 3em;
              padding-left: 2em;
              padding-right: 2em;
            }

            @media (max-width: 1000px) {
              display: block;
              font-size: 10pt;
              margin-bottom: 1em;
              padding-left: 3vw;
              padding-right: 3vw;
            }
          }

          .controls {
            width: stretch;
            font-size: 12pt;
            justify-content: center;

            @media (max-width: 1000px) {
              margin-bottom: 1.5em;
              margin-top: 1.5em;
              display: block;
            }

            @media (min-width: 1001px) {
              position: absolute;
              bottom: 2em;
            }

            a {
              @media (max-width: 1000px) {
                display: block;
                line-height: 2em;
              }
              @media (min-width: 1001px) {
                margin-left: 2em;
                margin-right: 2em;

              }

              svg {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}
