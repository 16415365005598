.side-panel {
  position: fixed;
  background: white;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  transform: translateX(-320px);
  transition: 200ms all;
  z-index: 2;

  @media (max-width: 600px) {
    width: 100%;
    transform: translateX(-100%);
  }

  &.is-open {
    transform: translateX(0);
  }

  .header {
    color: black;
    height: 50px;
    width: stretch;
    font-size: 18pt;
    line-height: 50px;
  }

  .row {
    width: 100%;
    height: 64px;
    text-align: center;
    position: relative;
    text-decoration: none;

    a {
      left: 0;
      position: absolute;
      width: stretch;
      height: stretch;
      line-height: 64px;
      font-size: 12pt;
      color: black;
      text-transform: uppercase;
      text-decoration: inherit;

      &:hover {
        background: lightgreen;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
