.campaign-explorer {
  .campaigns {
    .campaign-preview {
      .top-panel {
        .art-episode-container {
          flex: 1;

          @media (max-width: 1000px) {
            order: 1;
          }

          .artwork {
            height:16em;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .next-episode {
            padding: 1em;
            border-top: 1px solid white;
            bottom: 3em;

            @media (max-width: 1000px) {
              display: none;
            }

            &:hover {
              text-decoration: underline;
              cursor: pointer;
              background: #212121;
            }

            .content {
              width: stretch;
              position: relative;
              top: 0;
              height: 100%;

              .title {
                font-weight: bold;
                font-size: 13pt;
              }

              .date {
                margin-top: 0.25em;
                font-size: 9pt;
                font-style: italic;
              }

              .description {
                font-size: 9pt;
                line-height: 1.4em;
                font-style: italic;
                width: stretch;
                margin-top: 0.5em;
              }

              .controls {
                width: stretch;
                font-size: 10pt;
              }
            }
          }
        }
      }
    }
  }
}
